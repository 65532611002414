<template>
  <el-form size="small" :rules="rules" :model="form" ref="form" label-width="120px">
    <el-row>
      <el-col :span="24">
        <el-form-item label="冲减类型" prop="chongjianleixing">
          <el-radio-group v-model="form.chongjianleixing" @change="onChongjianleixing">
            <el-radio v-model="radio" label="0"> 工地回库冲减 </el-radio>
            <el-radio v-model="radio" label="1"> 在库冲减 </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item v-if="isShowContract" label="项目或工地" prop="contract" :rules="{ required: true, message: '请输入合同编号或工地', trigger: 'blur' }">
          <el-select v-model="form.contract" filterable remote placeholder="项目或工地" :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in XiangmuGongdiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item v-if="isShowCangku" label="所属仓库" prop="cangku">
          <el-select v-model="form.cangku" placeholder="请选择所属仓库">
            <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="材料名称" prop="cailiao">
          <el-select v-model="form.cailiao" placeholder="请选择材料名称">
            <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="材料规格" prop="cailiaoguige">
          <el-select v-model="form.cailiaoguige" placeholder="请选择材料规格">
            <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="损耗数量" prop="sunhaoshu">
          <el-input v-model.number="form.sunhaoshu"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="报损时间" prop="date">
          <el-date-picker
            v-model="form.date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择报损日期"
            :picker-options="pickerOptions1"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item label="上传图片：" prop="photos">
          <el-upload
            class="upload-demo"
            ref="upload"
            action="http://ln-jz.cn:8000/uploadImg/"
            multiple
            accept="image/png, image/jpeg,image/gif"
            list-type="picture"
            :on-remove="handleRemove"
            :file-list="fileList"
            :before-upload="beforeUploadPicture"
            :on-success="uploadSuccess"
            :auto-upload="false"
          >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且单个文件不超过10M</div>
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="损耗说明">
          <el-input v-model="form.remark" type="textarea" rows="3"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm('form')"> 取消 </el-button>
        <el-button type="primary" @click="submitForm('form')"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  name: 'consumeFrom',
  props: {},
  data() {
    return {
      radio: '0',
      form: {},
      isShowContract: true,
      isShowCangku: false,
      XiangmuGongdiOptions: [],
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      loading: false,
      fileList: [],
      file_count: 0,
      company: utils.getCompanyCookie(),
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      isValidationOk: false,
      rules: {
        contract: [{ required: true, message: '项目或工地不能为空', trigger: 'blur' }],
        cangku: [{ required: true, message: '请选择损耗库房', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        sunhaoshu: [{ required: true, message: '请选择损耗数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择报损时间', trigger: 'blur' }],
        chongjianleixing: [{ required: true, message: '请选择冲减类型', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //项目工地搜索框响应事件，从后台查询10条记录
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const api = '/getXiangmuGongdis/'
          this.$axios
            .get(api, {
              params: {
                query: query
              }
            })
            .then(res => {
              if (res.data.code === 1000) {
                this.XiangmuGongdiOptions = res.data.data.map(item => {
                  item.label = item.contract_id + '#' + item.project
                  item.value = item.id
                  return item
                })
              } else {
                console.log('没有项目工地信息！')
              }

              console.log('XiangmuGongdiOptions', this.XiangmuGongdiOptions)
            })
        }, 200)
      } else {
        this.XiangmuGongdiOptions = []
      }
    },
    getCangkus() {
      this.cangkus_options = []
      this.$axios
        .get('/getCangkus/', {
          params: {
            cangku_code: this.company
          }
        })
        .then(res => {
          console.log('getCangkus list:', res.data)
          res.data.forEach(element => {
            let cangku = {}
            cangku.label = String(element.name)
            cangku.value = element.id
            this.cangkus_options.push(cangku)
          })
          console.log('cangkus_options', this.cangkus_options)
        })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    //冲减类型单选按钮响应事件
    onChongjianleixing(val) {
      if (val === '1') {
        this.isShowContract = false
        this.isShowCangku = true
      } else {
        this.isShowContract = true
        this.isShowCangku = false
      }
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('validation ok!')

          this.$emit('submitForm', this.form)
          this.form = {}
          this.fileList = []
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('resetForm')
    },
    // 上传图片前调用方法
    beforeUploadPicture(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message.error('上传图片不能大于10M')
        return false
      }
    },
    // 上传图片成功
    uploadSuccess(res, file, fileList) {
      this.file_count += 1
      this.fileChange(fileList)
      console.log('uploadfilelist.len:', fileList.length)
      console.log('file_count:', this.file_count)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('RemovefileList', fileList)
      this.fileChange(fileList)
    },
    // 设置photo字段值
    fileChange(fileList) {
      let temp_str = ''
      this.file_count = fileList.length
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            //列表中的第一个,作为temp_str的第一个元素
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += '/api/' + fileList[i].response.data //本地
                temp_str += fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += fileList[i].url
            }
          } else {
            //不是列表中的第一个,插入到temp_str中
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += ',' + '/api/' + fileList[i].response.data //本地
                temp_str += ',' + fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += ',' + fileList[i].url
            }
          }
        }
      }
      this.form.photos = temp_str
      console.log('photos:', this.form.photos)
    },
    submitUpload() {
      this.$refs.upload.submit()
    }
  },
  created() {
    this.getCangkus()
    this.getCailiaos()
    this.getCailiaoguiges()
  }
}
</script>

<style scoped></style>
