<template>
  <div class="manage">
    <el-dialog
      width="700px"
      :visible.sync="isShow"
      title="添加新的损耗"
      :close-on-click-modal="false"
    >
      <sunhao-form
        ref="addconForm"
        @submitForm="submitForm"
        @resetForm="resetForm"
      ></sunhao-form>
    </el-dialog>
    <!-- 显示照片 -->
    <el-dialog
      width="1000px"
      :visible.sync="Photos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image v-for="url in photosurls" :key="url" :src="url"></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Photos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <div class="manage-header">
      <div class="left">
        <el-button
          type="primary"
          v-if="
            usertype.code === '6' ||
              usertype.code === '4' ||
              usertype.code === '2'
          "
          @click="isShow = true"
        >
          添加新的损耗
        </el-button>
        <el-button type="primary">
          导出数据
        </el-button>
      </div>
      <div class="right">
        <el-input
          placeholder="项目 | 工地 | 仓库 | 材料 | 材料规格"
          v-model="keyword"
          @keyup.enter.native="getTableData()"
        >
          <el-button slot="append" @click="getTableData()"> 查询 </el-button>
        </el-input>
      </div>
    </div>
    <sunhao-table
      :tableData="tableData"
      :config="config"
      :userType="usertype"
      @Wuzi_shenhe="Wuzi_shenhe"
      @Caiwu_shenhe="Caiwu_shenhe"
      @changePage="getTableData"
      @showPhotos="showPhotosinTable"
    ></sunhao-table>
  </div>
</template>

<script>
import SunhaoForm from './sunhaoForm'
import SunhaoTable from './sunhaoTable'
import utils from '@/assets/js/utils'
export default {
  components: {
    SunhaoForm,
    SunhaoTable
  },
  data() {
    return {
      isShow: false,
      keyword: '',
      usertype: '',
      user_realname: '',
      tableData: [],
      sunhaoFormData: {},
      Photos_isShow: false,
      photosurls: [],
      company: utils.getCompanyCookie(),
      config: {
        page: 1,
        total: 0,
        loading: false
      }
    }
  },
  methods: {
    getTableData() {
      this.config.loading = true
      console.log('find:', find)
      const api = '/getSunhaoxinxis/'
      this.$axios
        .get(api, {
          params: {
            company: this.company, //当前用户所属分公司编号code
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          console.log(res.data)
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            this.tableData = res.data.data.map(item => {
              if (typeof item.contract_id === 'undefined') {
                item.xiangmuorgongdi = '库房冲减'
              } else {
                item.xiangmuorgongdi =
                  item.contract_id + '#' + item.contract_project
              }
              item.cailiao_cailiaoguige =
                item.cailiao_name + item.cailiaoguige_name
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            console.log('没有损耗信息！')
          }
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //提交损耗表单
    submitForm(formData) {
      this.sunhaoFormData = formData
      console.log('sunhaoFormData........:', this.sunhaoFormData)
      this.sunhaoFormData.added_person = this.user_realname

      this.$axios.post('/addSunhaoxinxi/', this.sunhaoFormData).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '增加损耗信息成功!',
            duration: 2000
          })
          this.resetForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetForm() {
      this.isShow = false
      this.sunhaoFormData = {}
    },
    Caiwu_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/sunhaoCaiwushenhe/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },
    Wuzi_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/sunhaoWuzishenhe/?id=' + row.id).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '审核成功！'
            })
            this.getTableData()
          } else {
            this.$message({
              message: '审核失败'
            })
          }
        })
      })
    },
    //在table中显示出库图片
    showPhotosinTable(row) {
      this.photosurls = []
      let photos = row.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地

        this.photosurls.push(url)
      }
      console.log(this.photosurls)
      this.Photos_isShow = true
    }
  },
  created() {
    let user = utils.getUserCookie()
    this.usertype = user.user_type
    this.user_realname = user.name
    console.log('usertype:', this.usertype)
    console.log('user_realname:', this.user_realname)
    this.config.loading = true
    this.getTableData()
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}
.my-table {
  margin-right: 20px;
  margin-left: 20px;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
